<template>
  <div class="d-flex flex-column align-items-center mt-2 vue-container col-12">
    <img @click="this.$router.push('/')" class="back-arrow" src="../../assets/back-btn.png">
    <div class=" d-flex flex-column text-center align-items-center header-text mt-5 col-10">
      TERMS & CONDITIONS
    </div>
    <img class="game-logo col-6" src="../../assets/gameLogos/head-2-head-logo.png">
    <img @click="playNowClicked()" class="col-5" src="../../assets/play-now-btn.png">
    <div class="mt-2 col-9 info-text">
      WELCOME TO THE QUIZINC HEAD 2 HEAD TERMS AND CONDITIONS PAGE. PLEASE SELECT THE DROP DOWN THAT SUITS YOUR PURPOSES
      BELOW.
    </div>
    <div class="mt-2 header-text">
      QUIZINC HEAD 2 HEAD
    </div>
    <div class="d-flex flex-column col-11 mt-2 terms-conditions-container">
      <div @click="toggleDropdown(0)" class="d-flex flex-row">
        <div class="col-11 terms-conditions-dropdown-header">SERVICE TERMS</div>
        <div class="col-1">
          <img v-if="!isDropdownOpen(0)" class="col-5" src="../../assets/plus-btn.png">
          <img v-if="isDropdownOpen(0)" class="col-5" src="../../assets/minus-btn.png">
        </div>
      </div>
      <div class="terms-conditions-text mt-1" v-if="isDropdownOpen(0)">
        <div class="accordion">
          <div class="accordion-content">
            <p>
              1. These terms and conditions govern the relationship between You (“You” or “Your”) and
              Vodacom Proprietary Limited and its affiliates (hereinafter “Vodacom” or “Us” or “We”)
              and Fanclash South Africa (Pty) Ltd (“the Partner”) regarding Your use of the Quizinc
              Head2Head Service (the “Service”) (“Terms and Conditions”). Use of the Service is
              also governed by the Privacy Policy which is incorporated herein by reference.
            </p>
          </div>
          <div class="accordion-content">
            <p>
              2. These terms and conditions for the Service shall be read together with the Partner’s
              terms and conditions available at: <a href="https://www.vodacom.co.za/vodacom/privacy-policy/terms">http://quizinc.co.za/terms_and_conditions</a>
            </p>
          </div>
          <div class="accordion">
            <p class="paragraph uppercase">
              Important provision: Acceptance of these terms and conditions
            </p>
            <div class="accordion-content">
              <p>
                3. PLEASE READ THESE TERMS AND CONDITIONS AND THE PRIVACY POLICY
                CAREFULLY BEFORE USING THE SERVICE OR SUBMITTING INFORMATION
                THROUGH THE SERVICE. BY USING THE SERVICE OR SUBMITTING
                INFORMATION THROUGH THE SERVICE, YOU ARE AGREEING TO THESE
                TERMS AND CONDITIONS. IF YOU DO NOT AGREE, PLEASE DO NOT USE THIS
                SERVICE OR SUBMIT ANY INFORMATION THROUGH THE SERVICE. WE
                RESERVE THE RIGHT, AT ANY TIME, TO MODIFY AND UPDATE THESE TERMS
                AND CONDITIONS FROM TIME TO TIME BY POSTING SUCH UPDATED TERMS
                AND CONDITIONS ON THE VODACOM WEBSITE. ANY SUCH UPDATES SHALL
                APPLY TO YOU AND YOUR USE OF THE SERVICE AND BY CONTINUING TO USE
                THE SERVICE, YOU SHALL BE DEEMED TO HAVE ACCEPTED SUCH CHANGES.
              </p>
            </div>
          </div>
          <div class="accordion">
            <p class="paragraph uppercase">
              SUBSCRIBE OR UNSUBSCRIBE TO THE SERVICE?
            </p>
            <p>
              3.1. In order to subscribe to the Service, You must:
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.1.1 dial USSD: *117*74# and follows the on-screen subscription steps; or
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.1.2 visit Web URL: <a href="https://quizhead2head.co.za">https://quizhead2head.co.za</a> and follow the
              on-screen subscription steps.
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.2 You can also unsubscribe from the service by:
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.2.1 calling Vodacom Customer Care: 082 135;
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.2.2 using Vodacom self-service channels;
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.2.3 dialing USSD: *135*997# and follow the on-screen steps;
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.2.4 following the “cancel” prompts available within the Service menu,
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.2.5 visit the Web URL: <a href="https://quizhead2head.co.za/menu">https://quizhead2head.co.za/menu</a>
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.2.6 opening the MyVodacom App and navigate to Manage Services and follow the on- screen steps to
              unsubscribe from services; or
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.2.7 opening the VodaPay Mini App for: MyVodacom App and navigate to Manage Services and follow the
              on-screen steps to unsubscribe from services.
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.3 When cancelling/ unsubscribing from the Service, You accept that Your old profile and
              preferences will be retained for a 6 (six) month period and for no longer than 5 years
              (only if necessary), in order to facilitate a convenient experience should You choose to
              re-subscribe at a later stage within the 6 (six) month period.
            </p>
          </div>
        </div>
        <div class="accordion">
          <p class="paragraph uppercase text-decoration-underline">
            GENERAL:
          </p>
          <div class="accordion-content">
            <p>
              3.4 As a user of the Service, You will have the option to subscribe on a recurring basis,
              making You a paying user and doing so accordingly binds You to these Terms and
              Conditions. As a subscribed user, You will be asked to submit personal information to
              us. We are committed to ensuring responsible practices in the collection, use and
              disclosure of Your personal information in accordance with applicable law and the
              Privacy Policy incorporated by reference into and form a binding part of these Terms
              and Conditions.
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.5 By subscribing to the Service, You agree to the following additional rules upon
              successful initial registration for the Service:
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.5.1 You will be eligible to access all the content on the Service free of charge for 1 (one)
              day (the “Free Trial Day”). For the avoidance of doubt, You will not be eligible for
              another Free Trial Day if You cancel Your subscription and then re-register at a later
              stage.
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.5.2 After the Free Trial Day, You will be charged a subscription fee of ZAR 3 (three
              Rand) per day (incl. VAT) (the “Fee”) for the relevant period that You remain
              subscribed for the Service.
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.6 If You are a pre-paid or top-up user:
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.6.1 the Fee will be deducted from Your available airtime balance depending on the
              subscription that you have chosen. You acknowledge that You must have enough
              airtime available to pay the Fee to continue to use the Service;
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.6.2 if You fail to satisfy Your Fee obligation for 30 (thirty) consecutive days after the last
              successful Fee was collected, Vodacom may at its sole discretion suspend or
              terminate Your subscription to the Service;
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.6.3 the Fee for each day of the month that You were subscribed to the Service for will be
              set out in Your monthly statement for payment. If You do not pay Your monthly Fee
              set out in Your monthly statement, Your subscription may be suspended;
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.6.4 before the expiry of the Free Trial Day, You may cancel Your subscription to the
              Service to avoid being charged the Fee.
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.6.5 only one subscription is permitted per mobile number. Vodacom users within the
              Republic of South Africa may subscribe.
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.7 If You are a contract user, the Fee will be set out in Your monthly statement. If You
              do not pay Your monthly Fee, Your subscription may be suspended by Vodacom in its
              discretion.
            </p>
          </div>
        </div>
        <div class="accordion">
          <p class="paragraph uppercase text-decoration-underline">
            MICRO- BILLING:
          </p>
          <div class="accordion-content">
            <p>
              3.8 If Vodacom are unable to bill the full value of the subscription You have chosen for the
              Service, we may implement micro-billing. Micro billing occurs when You have
              subscribed to Service and You do not have sufficient funds in Your account (i.e., Your
              airtime balance) to pay for the subscription that You have chosen. Micro-billing will
              allow Vodacom to charge a discounted subscription or any reduced amount as we
              may determine from time to time dependent on the available balance until we can
              charge You the full amount. If we are successful in billing an amount, in part of in full
              for the end of the applicable period (depending on the subscription chosen), that billing
              record will stand as the completed billing for that particular period. Billing for the next
              period will revert back to attempting to bill the full stated price. Billing does not occur in
              arrears. Micro-billing shall be implemented in Vodacom’s sole and absolute discretion.
              You must have enough airtime available for payment of the subscription You have
              chosen for the Service to keep Your subscription in an active state. You acknowledge
              that in the event we are unable to bill any valid value for Your subscription of the
              Service that You have chosen, we will continue to attempt to bill and enable the
              subscription for a maximum period of 30 (thirty)consecutive days after the last
              successful bill collected. Should we be unable to collect any funds for a period of
              30(thirty) consecutive days, You will be automatically unsubscribed from the Service.
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.9 You give us consent to provide You with various messages about / based on Your
              interaction with the Service. These messages will be delivered to You in the form of
              text messages sent to Your mobile device using the mobile number identifying You as
              a user of the Service.
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.10 These messages include, but are not limited to:
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.10.1 periodic messages to encourage, or remind You to browse or access the Service;
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.10.2 messages inviting You to share, voluntarily, data about Yourself or Your preferences;
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.10.3 messages inviting You to try alternate versions of the Service based on Vodacom’s
              knowledge of the device You are using to access the Service;
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.10.4 confirmation messages relating to Your subscribing for, or cancellation of, the
              Service;
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.10.5 messages inviting You to rate the Service’s quality and Your opinion of Your
              experience of the Service as measured by the metric scale provided;
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.10.6 messages with Service content, including, but not limited to, confirmation of weekly
              results/ draws/ picks, and;
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.10.7 messages relating to the earning, notification, and servicing of rewards.
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.11 You may opt out of the messages above except for the system messages which are
              mandatory in order for us to correctly administrate Your use of the Service. To opt out
              of the messages, You are advised to make use of the functionality on the Service
              settings to cancel messages, or reply STOP to the text message.
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.12 You will not be charged for interactions that You will have with the Service via SMS.
              Data charges may apply for accessing the Service on website.
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.13 You accept that the Service’s default language is English.
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.14 By subscribing, You agree that You will be opted into receiving the messages
              mentioned above, which You may cancel at any time via the Service menu.
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.15 You acknowledge that Your subscription information will be retained for use to allow
              You access to future iterations and new features of the Service, and that You will be
              engaged to continue using the Service as applicable for other functionality intended to
              be covered by the Service.
            </p>
          </div>
          <div class="accordion-content">
            <p>
              3.16 You acknowledge that any subscriptions in error will be billed. For Your protection, we
              ask You to confirm all purchases twice.
            </p>
          </div>
          <p class="paragraph uppercase text-decoration-underline">
            REWARDS &amp; PRIZES (WHERE APPLICABLE)
          </p>
          <div class="accordion-content">
            <p>
              3.17 If applicable and to be eligible for rewards from the Service, You acknowledge that
              You need to have an active subscription for the Service.
            </p>
            <p>
              3.18 You acknowledge, understand, and agree that Your subscription to the Service does
              not guarantee any prizes or rewards from the Service. We shall not have any liability
              for such loss or damage while using the Service.
            </p>
            <p>
              3.19 Where applicable, prizes may not be substituted, sold or transferred. All taxes
              associated with the receipt or use of any prize is the sole responsibility of the winner. If
              any legal authority contests the awarding of a prize, we may, in our sole discretion,
              determine whether or not to award such a prize.
            </p>
            <p>
              3.20 Rewards can only be earned if You are a Vodacom customer. Any non-Vodacom
              customers will be unable to earn rewards due to their being active on a different
              network.
            </p>
            <p>
              3.21 Only the qualifying Vodacom mobile number that is subscribed to the Service, can
              earn rewards.
            </p>
            <p>
              1.1 In the event that You terminate Your Vodacom contract, You accept that You forfeit
              the use of any unused rewards, points and coins by the time of termination, as well as
              the earning of future rewards from that point on.
            </p>
            <p class="paragraph uppercase text-decoration-underline">
              GAME RULES
            </p>
            <p>
              3.22 You will play against another player in matches where each match consists of 10 (ten)
              trivia questions. You have a maximum of 20 (twenty) second to answer each question,
              the winner is the one between the 2 (two) players who answers the most questions
              correctly. In the event of a tie, the player who answered the questions in the quickest
              time wins.
            </p>
            <p>
              3.23 If You are found cheating in any way whether, making use of a bot, hacking or any
              other form of leader board manipulation, You will be suspended, Your coins will be
              forfeited and You will be unsubscribed from the service. Known offenders may be
              blacklisted from the service.
            </p>
            <p>
              3.24 There are 2 (two) ways in which You can play a match, as a Limited Free-to-Play user
              or as a Subscribed user. Match rules differ between a Limited Free-to-Play user and a
              Subscribed user – as described below:
            </p>
            <p>
              3.24.1 Limited Free-to-Play:
            </p>
            <p>
              3.24.1.1 As a free user You will receive 5 (five) coins for the first time You register for the
              Service.
            </p>
            <p>
              3.24.1.2 In order for You as a free user to receive more coins, You will need to subscribe
              to the R3 (three Rand) per day subscription as per clauses 3.1 to 3.5 above.
            </p>
            <p>
              3.24.1.3 To play a match consisting of 10 (ten) questions, You must use 1 (one) coin.
            </p>
            <p>
              3.24.1.4 You have 2 (two) options for matches, You can either be auto paired with a
              random player or be invited by a subscribed user to a match.
            </p>
            <p>
              3.24.1.5 User gameplay end-match results:
            </p>
            <p>
              3.24.1.5.1 Win: Match winner receives 1 (one)coin.
            </p>
            <p>
              3.24.1.5.2 Lose: Match loser receives 0 (zero) coins.
            </p>
            <p>
              3.24.1.5.3 Tied: Each participant receives 1 (one) coin.
            </p>
            <p>
              3.24.1.6 Coins are not transferrable.
            </p>
            <p>
              3.24.2 Subscription:
            </p>
            <p>
              3.24.2.1 As a daily subscriber, You will receive 5 (five) coins per day, if they are successfully
              charged for that day. Coins received via the successfully billed daily subscription
              will only be valid for 48 (forty-eight) hours. If coins are not claimed within 48 (forty-
              eight) hours, coins will be forfeited.
            </p>
            <p>
              3.24.2.2 In order for You to play a game, user must use 1 (one) coin to play a match
              consisting of 10 (ten) questions
            </p>
            <p>
              3.24.2.3 You have 3 (three) options for matches, You can either be auto paired with a
              random player, invite a subscribed user to a match, or invite a non-subscribed
              user to a match. You have maximum 1 (one) invitation per day
            </p>
            <p>
              3.24.2.4 Subscriber gameplay end-match results:
            </p>
            <p>
              3.24.2.4.1 Win: Match winner receives 2 (two) coins
            </p>
            <p>
              3.24.2.4.2 Lose: Match loser receives 0 (zero) coins
            </p>
            <p>
              3.24.2.4.3 Tied: Each participant receives 1 (one) coin
            </p>
            <p>
              3.24.2.5 Coins are not transferrable.
            </p>
            <p>
              3.24.2.6 You will forfeit all coins if You unsubscribe.
            </p>
            <p>
              3.25 The subscription process (and terms and conditions) required for the Service shall at
              all times be applicable and shall constitute an agreement between You and the
              Partner. Vodacom shall not be liable for any unavailability, faults glitches, bugs, or
              technical issues with the Partner’s application and/or URL. Furthermore, Vodacom
              shall not be held liable for any damages or loss howsoever arising that You may suffer
              due to use of the Partner’s application and/or URL.
            </p>
            <p>
              3.26 If You use the applications outside of the Service, You will be charged as per Your
              existing tariff plan. If You follow a link outside the Partner’s application and/or URL,
              normal data rates will apply as per Your existing tariff plan.
            </p>
            <p>
              3.27 Your data bundle depletion will not occur for any banner advertising or in-stream
              advertising that may be present within the Service.
            </p>
            <p>
              3.28 When making use of the Service, certain applications and/or URL may contain third
              party advertisements, content, materials, and/or links to third party services and / or
              websites that are not owned or controlled by Vodacom. Vodacom assumes no
              responsibility for, the content, privacy policies or practices of any third-party websites
              or applications. In addition, Vodacom does not and cannot censor or edit the content
              of any third-party website. When You visit third party websites, Vodacom encourages
              You to read the terms and conditions and privacy policies of the other websites. By
              using the Service, You expressly release Vodacom from all liability arising from Your
              use of any third-party website or because of any third-party services.
            </p>
            <p>
              3.29 If You perform a price plan migration, whilst using You have an existing active Service,
              such Service shall be carried over to the new price plan.
            </p>
            <p>
              3.30 If You perform a price plan upgrade, You will be able to add the Service to Your
              upgraded price plan.
            </p>
            <p>
              3.31 Vodacom reserves the right to terminate or change the Service at any time and for
              whatsoever reason provided the on-going subscription for the Service will not be
              affected
            </p>
            <p class="paragraph uppercase text-decoration-underline">
              YOUR OBLIGATIONS AND ENTITLEMENTS
            </p>
            <p>
              Vodacom and/or its appointed Partner reserves the right to determine what conduct it
              considers to be in violation of the rules of use or otherwise outside the intent or spirit of
              these Terms and Conditions or the Service itself. We reserve the right to act as a
              result, which may include, if relevant, suspending the Service, terminating Your
              registration, and prohibiting You from using the Service. Said suspension, termination
              and prohibition may occur with or without notice to You, and as a result may involve
              the loss of any benefits, privileges or earned items associated with Your use of the
              Service, and we are under no obligation to compensate You for any such losses or
              results.
            </p>
            <p class="paragraph uppercase text-decoration-underline">
              LIMITATION OF LIABILITY
            </p>
            <p>
              3.32 You agree that Your use of the Service shall be at Your sole risk. To the fullest extent
              permitted by law, we and our officers, directors, employees, and agents disclaim all
              warranties, express or implied, in connection with the services and Your use thereof.
              We make no warranties or representations about the accuracy or completeness of the
              Service’s content or the content of any websites or services linked to these services
              and assume no liability or responsibility for any (i) errors, mistakes, or inaccuracies of
              content; (ii) personal injury or property damage of any nature whatsoever resulting
              from Your access to and use of the Service; (iii) any unauthorized access to or use of
              our servers and/or any and all personal information and/or financial information stored
              therein; (iv) any interruption or cessation of transmission to or from the Service; (v) any
              bugs, viruses, trojan horses or the like which may be transmitted to or through the
              Service by any third party; or (vi) any errors or omissions in any content or for any loss
              or damage of any kind incurred as a result of the use of any content posted, emailed,
              transmitted, or otherwise made available via the Service. We do not warrant, endorse,
              guarantee, or assume responsibility for any product or Service advertised or offered by
              a third party through the Service or any hyperlinked website or featured in any banner
              or other advertising, and we will not be a party to or in any way be responsible for
              monitoring any transaction between You and third-party providers of products or
              services.
            </p>
            <p>
              3.33 To the extent permitted by applicable law, in no event shall we or our subsidiaries and
              affiliates, and their respective officers, directors, owners, agents, employees,
              representatives and licensors be liable for any special, incidental, indirect,
              consequential, punitive or exemplary losses or damages whatsoever or for loss of
              profits (including, without limitation, damages for loss of revenue, loss of data, failure
              to realize expected savings, interruption of activities, or any other pecuniary or
              economic loss) and whether arising from breach of contract, damages (including
              negligence), strict liability or otherwise, arising out of the use of or inability to use the
              Service or its content, or any product or service described or provided through the
              Service.
            </p>
            <p>
              3.34 Save to the extent permitted by law, You agree to indemnify, defend and hold
              harmless Vodacom from and against any and all claims, demands, actions, liability,
              losses, costs and expenses (including legal fees and expenses) arising from or related
              to: (i) any use or alleged use of the Service by any other person, whether or not
              authorized by You; (ii) Your breach of these Terms and Conditions, including any
              breach of Your representations and warranties herein; and (iii) Your violation of any
              law or (iv) Your use of or inability to use the Service, the contents, any hyperlinked
              website, and any of the products and services made available on the Service thereof.
            </p>
          </div>
        </div>
      </div>
      <img class="mt-3 col-12" src="../../assets/divider-line.png">
    </div>
    <div class="d-flex flex-column col-11 mt-2 terms-conditions-container">
      <div @click="toggleDropdown(1)" class="d-flex flex-row">
        <div class="col-11 terms-conditions-dropdown-header">HEAD 2 HEAD AUGUST COMPETITION</div>
        <div class="col-1">
          <img v-if="!isDropdownOpen(1)" class="col-5" src="../../assets/plus-btn.png">
          <img v-if="isDropdownOpen(1)" class="col-5" src="../../assets/minus-btn.png">
        </div>
      </div>
      <div class="terms-conditions-text mt-1" v-if="isDropdownOpen(1)">
        <div class="terms-conditions-text mt-1" v-if="isDropdownOpen(1)">
          <div class="accordion">
            <p class="paragraph uppercase text-decoration-underline">
              1 Introduction:
            </p>
            <p>
              1.1 Vodacom (Pty) Ltd (“Vodacom”) and Fanclash South Africa (Pty) Ltd (“Partner”) are running a
              Competition where customers stand a chance of winning their share of R12 199.00 (twelve thousand one
              hundred and ninety-nine Rand) worth of monthly prizes by subscribing to the Quizinc service and playing
              the Quizinc Head 2 Head game (the “Competition”).
            </p>
            <p class="paragraph uppercase text-decoration-underline">
              IMPORTANT PROVISION: ACCEPTANCE OF THESE TERMS AND CONDITIONS
            </p>
            <p class="paragraph uppercase text-center text-decoration-underline">
              PLEASE READ THESE TERMS AND CONDITIONS AND THE  PRIVACY POLICY
              CAREFULLY BEFORE PARTICIPATING IN THIS COMPETITION. BY ENTERING
              THE COMPETITION, YOU ARE AGREEING TO BE BOUND BY THESE TERMS AND
              CONDITIONS. IF YOU DO NOT AGREE, PLEASE DO NOT ENTER THE
              COMPETITION OR SUBMIT ANY INFORMATION IN THIS REGARD. WE RESERVE
              THE RIGHT, AT ANY TIME, TO MODIFY AND UPDATE THESE TERMS AND
              CONDITIONS FROM TIME TO TIME BY POSTING SUCH UPDATED TERMS AND
              CONDITIONS ON THE VODACOM WEBSITE. ANY SUCH UPDATES SHALL APPLY
              TO YOU AND YOUR ENTRY INTO THE COMPETITION AND YOU SHALL BE
              DEEMED TO HAVE ACCEPTED SUCH CHANGES.
            </p>
            <p class="paragraph uppercase text-center text-decoration-underline">
              ALL PARTICIPANTS TO THE COMPETITION AGREE TO BE BOUND BY THE
              FOLLOWING TERMS AND CONDITIONS:
            </p>
            <p class="paragraph uppercase text-decoration-underline">
              2 Duration:
            </p>
            <p>
              2.1 This Competition shall commence on 00h01 on 01 August 2024 to 23h59 on 31 August 2024 (the “End Date”)
              (“the Competition Period”).
            </p>
            <p>
              2.2 The duration of the Competition may be extended or curtailed at the discretion of
              Vodacom.
            </p>
            <p>
              2.3 The competition is limited to the number of Vouchers and will continue until the supply
              is exhausted. Vodacom reserves the right to retract, amend or forgo the Competition at
              any point during the Competition Period.
            </p>
            <p class="paragraph uppercase text-decoration-underline">
              3 Prizes:
            </p>
            <p>
              3.1 Participants to the Competition stand a chance to win:
            </p>
            <p>
              3.1.1 one of the following 2 (two) Monthly Prizes:
            </p>
            <p>
              3.1.1.1 1st Place Prize (“Grand Prize”) – One (1) Samsung Galaxy A34 5G Black worth
              R5 999.00 (five thousand nine hundred and ninety-nine Rand) monthly, based on
              the participant with the most accumulated coins at the Competition Period End
              Date;
            </p>
            <p>
              3.1.1.2 their share of R3 400.00 (three thousand four hundred Rand) worth of random lucky
              draw monthly Shoprite/Checkers vouchers (“Vouchers”), broken down as follows:
            </p>
            <p>
              3.1.1.2.1 One (1) Shoprite/Checkers Voucher daily, limited and allocated as shown in Table 1 below:
            </p>
            <div class="mt-2">
              Table 1:
              <table id="points-allocated">
                <thead>
                <tr>
                  <th>Voucher Type</th>
                  <th>Voucher Value</th>
                  <th>Number of Monthly Vouchers</th>
                  <th>Total Value</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>Shoprite/Checkers Voucher</td>
                  <td>R200.00</td>
                  <td>31</td>
                  <td>R6 200.00</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="mt-2">
              3.1.2 The Voucher entitles the participant to Shoprite &amp; Checkers Vouchers up to the
              maximum value of R200 (five hundred Rand), with the following conditions:
              <div>- The Voucher is valid for 3 (three) years.</div>
              <div>- The Voucher cannot be exchanged for cash or credit.</div>
              <div>- The Voucher cannot be replaced if lost or stolen.</div>
              <div>- This virtual Voucher can only be used in-store and cannot be used online.</div>
              <div>- The SMS should be kept until the Voucher has been redeemed.</div>
              <div>- Vouchers can be redeemed only once.</div>
              <div>- Vouchers cannot be redeemed at Usave mobile stores, MediRite Pharmacies
                or at Money Market counters.
              </div>
              <div>- No change will be given if the value of the purchase for which the Voucher is
                used is less than the value of the Voucher.
              </div>
              <div>- If the full value of the Voucher is not going to be redeemed in one transaction,
                the remaining value will be loaded onto a gift card in store.
              </div>
              <div>- Should the Voucher be lost or stolen, neither Shoprite &amp; Checkers nor its
                agents will be liable for any reimbursement of any kind.
              </div>
            </div>
            <div class="mt-2">
              3.1.2.1.1 For specific gift card usage terms, please read the Shoprite voucher terms and conditions found
              on this link:
              https://tickets.computicket.com/event/shoprite_group_virtual_grocery_vouchers/7145292/7165772/107235
            </div>
            <div class="mt-2">
              3.1.2.1.2 Vouchers will have a redemption validity of 7 (seven) days, winners must access
              the Quizinc Head 2 Head service on: quizhead2head.co.za or by dialling *117*74#
              and claim the reward voucher to receive the voucher code. Any qualifying
              Vouchers unredeemed at this time will be forfeited.
            </div>
            <div class="mt-2">
              3.1.3 The total value of the Prize is R12 199.00 (twelve thousand one hundred and ninety-nine Rand) (“the
              Prize”).
            </div>
            <p class="paragraph uppercase text-decoration-underline">
              4 Eligibility:
            </p>
            <p>
              4.1 Only entries received during the Competition period will qualify to enter the
              Competition.
            </p>
            <p>
              4.2 The following requirements must be complied with during the Competition period in
              order to qualify for entry into the Competition:
            </p>
            <p>
              4.2.1 be a natural person;
            </p>
            <p>
              4.2.2 be at least 18 years and older;
            </p>
            <p>
              4.2.3 be a Vodacom prepaid, hybrid or post-paid subscriber during the Competition Period;
            </p>
            <p>
              4.2.4 use a SIM card that has been RICA registered; and
            </p>
            <p>
              4.2.5 Register and play the free Quizinc Head2Head service, or subscribe to the Quizinc
              Head 2 Head service for R3.00 (three Rand) per day, R20.00 (twenty Rand) per
              week, or to the Quizinc All Access Pass service for R25.00 (twenty-five Rand) per
              month and log into the Head 2 Head service during the Competition Period.
              Participants will only receive 5 free coins at first time registration, thereafter
              participants will only receive more coins to play the Head 2 Head game on the days
              that the subscription is successfully billed. Participants can access the Head 2 Head
              service on any of the following platforms:
            </p>
            <div class="mt-2">
              4.2.5.1 direct URL link: quizhead2head.co.za;
            </div>
            <div class="mt-2">
              4.2.5.2 main QuizInc URL link: www.quizinc.co.za and navigate to the Stop the Clock
              banner; or
            </div>
            <div class="mt-2">
              4.2.5.3 by dialling USSD: *117*74#
            </div>
            <div class="mt-2">
              4.2.6 play the QuizInc Head2Head Game either as a Free-to-Play player or a subscribed
              premium player, each coin that the player wins from each match they play in the
              Head2Head Game will count as an entry into the random lucky draw, as summarised
              in the table below:
            </div>
            <table id="points-allocated">
              <thead>
              <tr>
                <th>Feature</th>
                <th>Free Play</th>
                <th>Premium Play</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Initial Coins</td>
                <td>5 (five)</td>
                <td>5 (five) plus an additional 5
                  (five) bonus coins on
                  upgrade
                </td>
              </tr>
              <tr>
                <td>Coin Gain</td>
                <td>1 (one) per game</td>
                <td>Maximum of 2 (two) per
                  game win and 5 (five) for
                  every successful billing
                </td>
              </tr>
              <tr>
                <td>Maximum Coins</td>
                <td>5 (five)</td>
                <td>Unlimited</td>
              </tr>
              <tr>
                <td>Game Cost</td>
                <td>1 (one) coin per game</td>
                <td>1 (one) coin per game</td>
              </tr>
              <tr>
                <td>Game Outcome</td>
                <td>Win: regain the coin used to play,
                  Lose: -1 coin
                </td>
                <td>Win: +1 coin, Lose: -1 coin
                </td>
              </tr>
              <tr>
                <td>Game Time</td>
                <td>20 (twenty) seconds per question</td>
                <td>20 (twenty) seconds per question
                </td>
              </tr>
              <tr>
                <td>Add Friends</td>
                <td>Not allowed</td>
                <td>Allowed, via mobile
                  number entry
                </td>
              </tr>
              <tr>
                <td>Challenges</td>
                <td>Can participate but, cannot initiate</td>
                <td>Can initiate and participate</td>
              </tr>
              <tr>
                <td>Draw Entries</td>
                <td>1 (one) entry per coin, maximum 5 (five) entries per day</td>
                <td>1 (one) entry per coin, unlimited entries per day
                </td>
              </tr>
              <tr>
                <td>Data Reward</td>
                <td>Eligible - 10 (ten) users selected
                  daily
                </td>
                <td>Eligible - 10 (ten) users selected daily
                </td>
              </tr>
              <tr>
                <td>Upgrade/Downgrade</td>
                <td>Upgrade available on USSD: *117*74# or Web URL: <a href="https://quizhead2head.co.za">https://quizhead2head.co.za</a>
                </td>
                <td>Downgrade by dialling
                  *135*997# or via Web
                </td>
              </tr>
              </tbody>
            </table>
            <p>
              *Note: Each coin received from winning a game match constitutes an entry into the lucky
              draw. (e.g. if a player has earned 10 (ten) coins, they will have 10 (ten) entries into the daily
              lucky draw)*
            </p>
            <p class="text-center">
              Once or if all the requirements of paragraphs 4.2.1 to 4.2.6 are met, entry into the
              Competition will be automatic. Coins received from registration and the daily premium
              subscription allocation are not eligible for entry into the random lucky draw.
            </p>
            <p>
              4.3 In the event that the registered user of the SIM Card or Vodacom account (i.e., the
              participant in the Competition) is not the person that is responsible for payment of the
              Vodacom account/ charges on the Vodacom account, then the Prize will be awarded
              to the person that is responsible for payment of the Vodacom account.
            </p>
            <p>
              4.4 Standard data costs apply as per the user’s Service Provider and existing price plan
              for data usage. Vodacom shall not be liable for any costs incurred by the winners for
              claiming any Prizes, where applicable.
            </p>
            <p>
              4.5 Entry to the Competition is free. Standard data costs apply as per the user’s Service
              Provider and existing price plan for data usage. Vodacom shall not be liable for any
              costs incurred by the winners for claiming any Prizes, where applicable.
            </p>
            <p class="paragraph uppercase text-decoration-underline">
              5 Prize draw
            </p>
            <p>
              5.1 Monthly Prize Winners will be randomly selected daily at midnight from all eligible
              entries who meet all the requirements set out in clause 3 and 4 above.
            </p>
            <p>
              5.2 The Grand Prize Winner will be selected according to their position on the coins
              collected being the top of the leader board. Only the first place participant will be
              guaranteed the Grand Prize based on them having collected the most coins at the
              Competition Period End Date from all eligible entries who meet all the requirements
              set out in clause 3 and 4 above.
            </p>
            <p class="paragraph uppercase text-decoration-underline">
              6 Winner Validation
            </p>
            <p>
              6.1 All the information provided or related to this Competition shall be managed, captured
              and approved by Vodacom and/or the Partner (where applicable).
            </p>
            <p>
              6.2 Vodacom and/or the Partner (where applicable) will use reasonable efforts to contact
              the winners via SMS 1 (one) day after they have been drawn as Voucher winners on
              the mobile number used by the participants to enter the Competition.
            </p>
            <p>
              6.3 The claim for any Prize will be subject to security, identification and validation, and
              Vodacom reserves the right to withhold any Prize until it is satisfied that the claim by a
              particular winner is valid. The selected winners must be able to identify themselves, in
              a manner determined by Vodacom including providing their South African identity
              number or a valid passport number, as the qualifying participants to the Competition
              and are required to comply with the validation procedure, determined by Vodacom, to claim any Prizes,
              failing which such winner will forfeit the Prize and the Prize will be
              awarded to the next selected qualifying participant.
            </p>
            <div class="mt-2">
              6.4. Failure to redeem the Prize within the stipulated 7 (seven) day period, will result in
              forfeiture of the Prize. Forfeited Vouchers will be returned to the Prize pool for future
              draws.
            </div>
            <div class="mt-2">
              6.5. Vodacom and/or the Partner (where applicable) shall attempt to contact the physical
              prize winner for a period of 05 (five) working days after their name has been drawn
              and verified as a winner. The winner will be contacted during normal working hours,
              between 09:00 to 16:00. The winner will be advised of arrangements in respect of the
              physical Prize at the time of contacting the selected winner. Should a participant not
              be available on the contact details provided during the timeframe stipulated above or
              rejects, forfeits or declines acceptance of the Prize, that person&#39;s right to the Prize will
              be deemed to have been waived and the Prize will be forfeited. Vodacom reserves the
              right to then award the Prize to the next highest scoring participant on the leaderboard.
              The winner will be contacted within 7 (seven) working days after the End Date.
            </div>
            <div class="mt-2">
              6.6. In the event that Vodacom requires certain documentation to be submitted by a
              particular winner and same has not been received at the agreed cut-off date
              communicated to the winner, or no effort is made on the part of any of the winners to
              make special arrangements to meet the deadline set by Vodacom, such Prize will be
              forfeited. Vodacom then reserves the right to award the Prize to the finalist that is next
              in line.
            </div>
            <div class="mt-2">
              6.7. Vodacom and/or the Partner (where applicable) shall request the winners’ consent in
              writing to their image and/or likeness being used and published by Vodacom in
              connection with the Competition for a period of 12 (twelve) months after they have
              been announced as winners. The winners may decline the use of their image and/or
              likeness by Vodacom.
            </div>
            <p class="paragraph uppercase text-decoration-underline">
              7 Prize Delivery
            </p>
            <div class="mt-2">
              7.1. To redeem the Vouchers, winners need to log on to the Quiz Head 2 Head service within
              the 7 (seven) day validity period by:
            </div>
            <div class="mt-2">
              7.1.1 using the direct URL link: quizhead2head.co,za, the winner will be presented with a
              popup that will allow them to view their reward; or
            </div>
            <div class="mt-2">
              7.1.2 dialling USSD: *117*74#, the winner will navigate the menu options to view and claim
              their Voucher.
            </div>
            <div class="mt-2">
              7.2. If a winner cannot accept a Prize for any reason whatsoever, the Prize will be forfeited.
            </div>
            <div class="mt-2">
              7.3. All Physical Prizes will be sent with a courier service to the winners’ physical
              addresses only. No Prizes will be delivered to a postal address.
            </div>
            <div class="mt-2">
              7.4. If a winner cannot accept a Prize for any reason whatsoever, the Prize will be awarded
              to the next selected participant.
            </div>
            <div class="mt-2">
              7.5. All risks and ownership of the Prizes shall pass to the winners on transfer/delivery
              thereof and hence all of Vodacom’s obligations regarding the Competition as well as in
              regard to the Prizes shall terminate.
            </div>
            <p class="paragraph uppercase text-decoration-underline">
              8 Personal information
            </p>
            <p>
              8.1 User data, including personal information collected via the Competition will not be
              used for any other purpose than for execution of the Competition and will be
              processed in line with the Vodacom’s Privacy Statement which is incorporated into
              these terms and conditions. The Privacy Statement sets out how amongst other things
              Vodacom collects, use and shares subscribers personal information and how it
              protects subscriber’s privacy when processing their information.
            </p>
            <p>
              8.2 By continuing, a subscriber confirms that the Vodacom Privacy Statement has been
              read and understood. The latest Privacy Statement can be found on the Vodacom
              website at <a href="https://www.vodacom.co.za/vodacom/privacy-
              policy/terms?icmp=Home/Footer/PrivacyPolicy">https://www.vodacom.co.za/vodacom/privacy-
              policy/terms?icmp=Home/Footer/PrivacyPolicy.</a>
            </p>
            <p class="paragraph uppercase text-decoration-underline">
              9 General
            </p>
            <div class="mt-2">
              9.1. Participants may obtain a copy of the competition rules on the URL:
              https://termsandconditions.quizinc.co.za/
            </div>
            <div class="mt-2">
              9.2. Nothing in these Terms and Conditions is intended to, or must be understood to,
              unlawfully restrict, limit or avoid any rights or obligations, as the case may be, created
              for either the participant or Vodacom in terms of the Consumer Protection Act, 68 of
              2008 (&quot;CPA&quot;).
            </div>
            <div class="mt-2">
              9.3. The Prize is not transferable and is not exchangeable for another Prize or cash and
              cannot be sold.
            </div>
            <div class="mt-2">
              9.4. The decision of Vodacom in respect of disputes arising out of this Competition shall be
              dealt with by Vodacom in terms of these Terms and Conditions. The decision of
              Vodacom in this regard shall be final and binding on the Parties and no
              correspondence will be entered into.
            </div>
            <div class="mt-2">
              9.5. The Grand Prize Winner is only entitled to win 1 (one) Grand Prize. However, where a
              participant has won but not accepted the Grand Prize within 7 (seven) days, the Grand
              Prize with be forfeited.
            </div>
            <div class="mt-2">
              9.6. In the event of a dispute with regard to any aspect of the Competition and/or the
              Terms and Conditions, Vodacom’s decision will be final, and binding and no
              correspondence will be entered into.
            </div>
            <div class="mt-2">
              9.7. Vodacom and/or the Partner may refuse to award a Prize if entry procedures or these
              Terms and Conditions have not been adhered to or if it detects any irregularities or
              fraudulent practices. Vodacom is entitled in its entire discretion to reject any participant
              for any reason and will not be obliged to notify participants that they have not been
              successful.
            </div>
            <div class="mt-2">
              9.8. Vodacom and/or the Partner and/or any other person or party associated with the
              Competition, their associated companies, agents, contractors and sponsors and any of
              its personnel involved in the Competition, shall not be liable whatsoever for any loss or
              damage incurred or suffered (including but not limited to direct or indirect or
              consequential loss), death or personal injury suffered or sustained arising from either the participant
              entering the Competition or from it claiming a Prize.
            </div>
            <div class="mt-2">
              9.9. Vodacom is not liable for any technical failures affecting participation and / or Prize
              redemption process of the Competition. In addition, neither Vodacom nor its agents
              shall be responsible for any loss or misdirected entries, including entries that were not
              received due to any failure of hardware, software, or other computer or technical
              systems affecting participating in and/or the Prize redemption process of the
              Competition.
            </div>
            <div class="mt-2">
              9.10. Vodacom, its directors, employees, agents, and distributors, are not responsible for
              any misrepresentation (whether written or verbal) in respect of any Prize nor in respect
              of any warranties, guarantees or undertakings given by any person other than
              Vodacom itself.
            </div>
            <div class="mt-2">
              9.11. If Vodacom elects in its sole and absolute discretion or is required by law to alter, vary
              or cancel any aspect of the Competition, it will have the right to terminate the
              Competition being offered, with immediate effect and upon written notice being
              published on the Vodacom website. In such event all participants hereby waive any
              rights which they may have against Vodacom and its associated companies, agents,
              contractors and/or sponsors.
            </div>
            <div class="mt-2">
              9.12. All information relating to the Competition which is published on any marketing
              material will form part of these Terms and Conditions. In the event of any conflict
              between such marketing material and these Terms and Conditions, these Terms and
              Conditions shall prevail.
            </div>
            <div class="mt-2">
              9.13. The Competition is also subject to, and must be read in conjunction with, Vodacom’s
              existing terms and conditions applicable to its website and mobile site respectively.,
              which terms and conditions are available on the Vodacom website.
            </div>
          </div>
        </div>
      </div>
      <img class="mt-3 col-12" src="../../assets/divider-line.png">
    </div>
    <div class="d-flex flex-column col-11 mt-2 terms-conditions-container">
      <div @click="toggleDropdown(2)" class="d-flex flex-row">
        <div class="col-11 terms-conditions-dropdown-header">FAQ'S</div>
        <div class="col-1">
          <img v-if="!isDropdownOpen(2)" class="col-5" src="../../assets/plus-btn.png">
          <img v-if="isDropdownOpen(2)" class="col-5" src="../../assets/minus-btn.png">
        </div>
      </div>
      <div class="terms-conditions-text mt-1" v-if="isDropdownOpen(2)">
        <div class="accordion">
          <div class="paragraph uppercase">1. What is Head 2 Head Quiz Game?</div>
          <div class="accordion-content">
            <p>
              Head 2 Head is a multiplayer quiz game available on both USSD and the
              web. Players challenge each other with 10 random multiple-choice questions
              per game.
            </p>
          </div>
        </div>
        <div class="accordion">
          <div class="paragraph uppercase">2. How do I start playing?</div>
          <div class="accordion-content">
            <p>
              You can start by signing up on the USSD or the web platform. Upon sign-up,
              you will receive 5 free coins to initiate the quiz contests.
            </p>
          </div>
        </div>
        <div class="accordion">
          <div class="paragraph uppercase">3. How much does it cost to play?</div>
          <div class="accordion-content">
            <p>
              Each quiz contest costs 1 coin. If you run out of coins in Free Play, you need
              to upgrade to Premium Play to continue playing.
            </p>
          </div>
        </div>
        <div class="accordion">
          <div class="paragraph uppercase">4. How do I upgrade to Premium Play?</div>
          <div class="accordion-content">
            <p>
              Upgrades can be made on either the USSD or the web platform. With an
              upgrade, you will receive 5 bonus coins.
            </p>
          </div>
        </div>
        <div class="accordion">
          <div class="paragraph uppercase">5. What happens if I don&#39;t claim my coins within 24 hours of payment in
            the
            Premium Play?
          </div>
          <div class="accordion-content">
            <p>
              Coins that are unclaimed within 24 hours post-payment will expire and will not
              be added to your balance.
            </p>
          </div>
        </div>
        <div class="accordion">
          <div class="paragraph uppercase">6. How is the winner of the game determined?</div>
          <div class="accordion-content">
            <p>
              The player who answers the most questions correctly wins. In case of a tie, both players will get their
              coins back.
            </p>
          </div>
        </div>
        <div class="accordion">
          <div class="paragraph uppercase">7. What do I earn if I win a game?</div>
          <div class="accordion-content">
            <p>
              In Free Play, winners retain their coin. In Premium Play, winners receive two
              coins – their own and their opponent&#39;s.
            </p>
          </div>
        </div>
        <div class="accordion">
          <div class="paragraph uppercase">8. Can I challenge my friends?</div>
          <div class="accordion-content">
            <p>
              Only Premium users can invite friends by entering their friend&#39;s mobile
              number. However, Free Play users can still participate in challenges if invited.
            </p>
          </div>
        </div>
        <div class="accordion">
          <div class="paragraph uppercase">9. What is the Daily Draw?</div>
          <div class="accordion-content">
            <p>
              Daily Draw is a random draw where users' coin balances determine entries. For each coin, you get one
              entry. One user is selected daily to win a R100 Checkers Voucher.
            </p>
          </div>
        </div>
        <div class="accordion">
          <div class="paragraph uppercase">10. How do I downgrade my package?</div>
          <div class="accordion-content">
            <p>
              To downgrade, you can dial *135*997# or visit the &#39;Manage My Package&#39;
              section in the terms and conditions on the web.
            </p>
          </div>
        </div>
        <div class="accordion">
          <div class="paragraph uppercase">11. Is there a time limit for each question?</div>
          <div class="accordion-content">
            <p>
              Yes, each question in a contest has a 20-second time limit. If unanswered
              within this duration, the question is marked incorrect.
            </p>
          </div>
        </div>
        <div class="accordion">
          <div class="paragraph uppercase">12. Why can&#39;t I add friends in Free Play?</div>
          <div class="accordion-content">
            <p>
              Adding friends is a feature exclusive to Premium Play users. Free Play users
              can, however, accept challenges from friends.
            </p>
          </div>
        </div>
        <div class="accordion">
          <div class="paragraph uppercase">13. How many entries can I have in the Daily Draw?</div>
          <div class="accordion-content">
            <p>
              In Free Play, you can have up to a maximum of 5 entries (1 entry per coin). In
              Premium Play, there&#39;s no limit on the number of entries.
            </p>
          </div>
        </div>
      </div>
      <img class="mt-3 col-12" src="../../assets/divider-line.png">
    </div>
    <div class="d-flex flex-column col-11  mt-2 mb-5 terms-conditions-container">
      <div @click="toggleDropdown(3)" class="d-flex flex-row">
        <div class="col-11 terms-conditions-dropdown-header">MORE HELP</div>
        <div class="col-1">
          <img v-if="!isDropdownOpen(3)" class="col-5" src="../../assets/plus-btn.png">
          <img v-if="isDropdownOpen(3)" class="col-5" src="../../assets/minus-btn.png">
        </div>
      </div>
      <div class="terms-conditions-text mt-1" v-if="isDropdownOpen(3)">
        <strong>How to get more help:</strong>
        <div class="mt-2">Please send hello@thefanclash.co.za an email with your Mobile Number and Name as the
          Subject.
        </div>
        <div class="mt-2">In the email please explain your problem and make sure to include what platform (i.e Quizinc)
          you are having an issue on.
        </div>
      </div>
      <img class="mt-3 col-12" src="../../assets/divider-line.png">
    </div>
  </div>
</template>

<script>
import {environmentConfig} from "@/config/enviromentConfig";
import {mapState} from "vuex";

export default {
  name: "HeadToHead",
  computed: {
    ...mapState(['encryptedMsisdn'])
  },
  data() {
    return {
      dropDownVisible: false,
      openDropdown: null,
      selectedDropDown: null,
    }
  },
  methods: {
    toggleDropdown(dropdown) {
      this.openDropdown = this.selectedDropDown === dropdown ? null : dropdown;
      this.selectedDropDown = this.openDropdown;
    },
    isDropdownOpen(dropdown) {
      return this.openDropdown === dropdown;
    },
    async playNowClicked() {
      let redirectUrl = environmentConfig.headToHeadRedirectUrl;
      if (this.encryptedMsisdn) {
        redirectUrl += `/?e-msisdn=${this.encryptedMsisdn}`;
      }
      window.open(redirectUrl, '_self');
    },
  },
}
</script>

<style scoped>
.vue-container {
  position: relative;
}

.header-text {
  font-size: 16px;
  font-weight: 800;
  z-index: 1;
}

.back-arrow {
  position: absolute;
  width: 80px;
  left: 5px;
  top: 10px;
}

.info-text {
  font-size: 14px;
  font-style: italic;
}

.header-text, .terms-conditions-dropdown-header {
  font-weight: 800;
}

.terms-conditions-dropdown-header {
  color: red;
}

.terms-conditions-text {
  font-size: 12px;
}

.accordion {
  margin: 0 0 20px 0;
  padding: 0;
}

.paragraph.uppercase {
  text-transform: uppercase;
  font-weight: bold;
}

#points-allocated {
  border-collapse: collapse;
  width: 100%;
  font-size: 11px;
  margin-bottom: 20px;
}

table, th, td {
  border: 1px solid;
}


</style>
