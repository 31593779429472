import store from "@/store";

export async function userAuthenticate(to, from, next) {
    const urlParams = new URLSearchParams(window.location.search);
    const encryptedMsisdn = urlParams.get('e-msisdn');

    if (encryptedMsisdn) {
        store.commit('setEncryptedMsisdn', encryptedMsisdn);
    }
    return next();
}






