import {createRouter, createWebHistory} from 'vue-router'
import {userAuthenticate} from "@/functions/userAuthenticate";
import LandingPage from '../views/LandingPage.vue'
import QuizInc from "@/views/termsConditions/QuizInc";
import StopTheClock from "@/views/termsConditions/StopTheClock";
import UnlockThree from "@/views/termsConditions/UnlockThree";
import HeadToHead from "@/views/termsConditions/HeadToHead";
import DailyFive from "@/views/termsConditions/DailyFive";

const routes = [
    {
        path: '/',
        name: 'LandingPage',
        component: LandingPage
    },
    {
        path: '/quiz-inc',
        name: 'QuizInc',
        component: QuizInc
    },
    {
        path: '/stop-the-clock',
        name: 'StopTheClock',
        component: StopTheClock
    },
    {
        path: '/unlock-three',
        name: 'UnlockThree',
        component: UnlockThree
    },
    {
        path: '/head-to-head',
        name: 'HeadToHead',
        component: HeadToHead
    },
    {
        path: '/daily-five',
        name: 'DailyFive',
        component: DailyFive
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        window.scrollTo(0, 0);
    }
})

router.beforeEach(async (to, from, next) => {
    await userAuthenticate(to, from, next);
})

export default router
