import {environmentConfig} from "@/config/enviromentConfig";
import axios from "axios";

const headers = {Authorization: `Basic ${environmentConfig.authHeader}`};

export default class DcbService {
    static async decryptMsisdn(encryptedMsisdn) {
        return await axios.post(`${environmentConfig.dcbService}/decrypt-msisdn`, {
                encryptedMsisdn,
            }, {headers}
        )
    }
}
