<template>
  <div class="d-flex flex-column align-items-center mt-2 vue-container col-12">
    <img @click="this.$router.push('/')" class="back-arrow" src="../../assets/back-btn.png">
    <div class=" d-flex flex-column text-center align-items-center header-text mt-5 col-10">
      TERMS & CONDITIONS
    </div>
    <img class="game-logo col-4" src="../../assets/gameLogos/daily-five-logo.png">
    <img @click="playNowClicked()" class="col-5" src="../../assets/play-now-btn.png">
    <div class="mt-2 col-9 info-text">
      WELCOME TO THE DAILY FIVE TERMS AND CONDITIONS PAGE. PLEASE SELECT THE DROP DOWN THAT SUITS YOUR PURPOSES
      BELOW.
    </div>
    <div class="mt-2 header-text">
      DAILY FIVE
    </div>
    <div class="d-flex flex-column col-11 mt-2 terms-conditions-container">
      <div @click="toggleDropdown(0)" class="d-flex flex-row">
        <div class="col-11 terms-conditions-dropdown-header">DAILY 5 VODAPAY COMPETITION</div>
        <div class="col-1">
          <img v-if="!isDropdownOpen(0)" class="col-5" src="../../assets/plus-btn.png">
          <img v-if="isDropdownOpen(0)" class="col-5" src="../../assets/minus-btn.png">
        </div>
      </div>
      <div class="terms-conditions-text mt-1 col-12" v-if="isDropdownOpen(0)">
        <div class="secondary-text mt-3 col-12">
          <div>
            1. Introduction
          </div>
          <div class="mt-3">
            1.1. Vodacom (Pty) Ltd (“Vodacom”) and Fanclash (Pty) Ltd (“Partner”) are running a
            Competition where customers stand a chance of winning their share of R60 000.00
            (sixty thousand Rand) worth of data and airtime prizes by registering an account on
            the Vodapay app and answering 5 (five) questions on the Quizinc Mini app (the
            “Competition”).
          </div>
          <div class="mt-3">
            <a style="text-decoration: underline">IMPORTANT PROVISION: ACCEPTANCE OF THESE TERMS AND CONDITIONS</a>.
          </div>
          <div class="mt-3">
            <a style="text-decoration: underline">PLEASE READ THESE TERMS AND CONDITIONS AND THE  PRIVACY POLICY
              CAREFULLY BEFORE USING THE SERVICE OR SUBMITTING INFORMATION
              THROUGH THE SERVICE. BY USING THE SERVICE OR SUBMITTING
              INFORMATION THROUGH THE SERVICE, YOU ARE AGREEING TO THESE
              TERMS AND CONDITIONS. IF YOU DO NOT AGREE, PLEASE DO NOT USE THIS
              SERVICE OR SUBMIT ANY INFORMATION THROUGH THE SERVICE. WE
              RESERVE THE RIGHT, AT ANY TIME, TO MODIFY AND UPDATE THESE TERMS
              AND CONDITIONS FROM TIME TO TIME BY POSTING SUCH UPDATED TERMS
              AND CONDITIONS ON THE VODACOM WEBSITE. ANY SUCH UPDATES SHALL
              APPLY TO YOU AND YOUR USE OF THE SERVICE AND BY CONTINUING TO USE
              THE SERVICE, YOU SHALL BE DEEMED TO HAVE ACCEPTED SUCH CHANGES.</a>.
          </div>
          <div class="mt-3">
            <a style="text-decoration: underline">ALL PARTICIPANTS TO THE COMPETITION AGREE TO BE BOUND BY THE
              FOLLOWING TERMS AND CONDITIONS:</a>.
          </div>
          <div class="mt-2">
            2. Duration:
          </div>
          <div class="mt-2">
            2.1 This Competition shall commence on 00h01 on 01 August 2024 to 23h59 on
            31 October 2024 (the “End Date”) (“the Competition period”).
          </div>
          <div class="mt-2">
            2.2 The duration of the Competition may be extended or curtailed at the discretion of
            Vodacom.
          </div>
          <div class="mt-2">
            3. Prizes:
          </div>
          <div class="mt-2">
            3.1 Participants to the Competition stand a chance to win:
          </div>
          <div class="mt-2">
            3.1.1 their share of airtime and data prizes broken down as follows:
          </div>
          <table id="points-allocated">
            <thead>
            <tr>
              <th class="col-3">August Prize Pool</th>
              <th class="col-2">Days</th>
              <th class="col-2">31</th>
              <th class="col-2"></th>
              <th class="col-2"></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Prize Description</td>
              <td>Prize Value</td>
              <td>Number of prizes</td>
              <td>Total Value</td>
              <td>Prizes Per Day</td>
            </tr>
            <tr>
              <td>Airtime</td>
              <td>2</td>
              <td>4000</td>
              <td>R 8 000.00</td>
              <td>129</td>
            </tr>
            <tr>
              <td>Airtime</td>
              <td>5</td>
              <td>2010</td>
              <td>R 10 050.00</td>
              <td>65</td>
            </tr>
            <tr>
              <td>Data 75MB</td>
              <td>13</td>
              <td>150</td>
              <td>R 1 950.00</td>
              <td>5</td>
            </tr>
            </tbody>
          </table>
          <table class="mt-3" id="points-allocated">
            <thead>
            <tr>
              <th class="col-3">September Prize Pool</th>
              <th class="col-2">Days</th>
              <th class="col-2">30</th>
              <th class="col-2"></th>
              <th class="col-2"></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Prize Description</td>
              <td>Prize Value</td>
              <td>Number of prizes</td>
              <td>Total Value</td>
              <td>Prizes Per Day</td>
            </tr>
            <tr>
              <td>Airtime</td>
              <td>2</td>
              <td>4000</td>
              <td>R 8 000.00</td>
              <td>133</td>
            </tr>
            <tr>
              <td>Airtime</td>
              <td>5</td>
              <td>2010</td>
              <td>R 10 050.00</td>
              <td>67</td>
            </tr>
            <tr>
              <td>Data 75MB</td>
              <td>13</td>
              <td>150</td>
              <td>R 1 950.00</td>
              <td>5</td>
            </tr>
            </tbody>
          </table>
          <table class="mt-2" id="points-allocated">
            <thead>
            <tr>
              <th class="col-3">October Prize Pool</th>
              <th class="col-2">Days</th>
              <th class="col-2">31</th>
              <th class="col-2"></th>
              <th class="col-2"></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Prize Description</td>
              <td>Prize Value</td>
              <td>Number of prizes</td>
              <td>Total Value</td>
              <td>Prizes Per Day</td>
            </tr>
            <tr>
              <td>Airtime</td>
              <td>2</td>
              <td>4000</td>
              <td>R 8 000.00</td>
              <td>129</td>
            </tr>
            <tr>
              <td>Airtime</td>
              <td>5</td>
              <td>2010</td>
              <td>R 10 050.00</td>
              <td>65</td>
            </tr>
            <tr>
              <td>Data 75MB</td>
              <td>13</td>
              <td>150</td>
              <td>R 1 950.00</td>
              <td>5</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-2">
          3.1.2 The total value of the Prize is R60 000.00 (sixty thousand Rand) (“the Prize”).
        </div>
        <div class="mt-2">
          4 Eligibility:
        </div>
        <div class="mt-2">
          4.1 Only entries received during the Competition period will qualify to enter the
          Competition.
        </div>
        <div class="mt-2">
          4.2 The following requirements must be complied with during the Competition period in
          order to qualify for entry into the Competition:
        </div>
        <div class="mt-2">
          4.2.1 be a natural person;
        </div>
        <div class="mt-2">
          4.2.2 be at least 18 years and older;
        </div>
        <div class="mt-2">
          4.2.3 be a Vodacom prepaid, hybrid or post-paid subscriber during the Competition Period;
        </div>
        <div class="mt-2">
          4.2.4 reside in South Africa and be in possession of a valid South African identify
          document or South African permanent residence permit or a South African temporary
          residence permit valid for a period exceeding 3(three) months which permits shall be
          valid as at the End Date;
        </div>
        <div class="mt-2">
          4.2.5 use a SIM card that has been RICA registered; and
        </div>
        <div class="mt-2">
          4.2.6 register an account on the Vodapay app, answer 5 (five) questions on the Quizinc
          Mini app, if the participant answers all 5 (five) questions correctly, they will
          automatically enter into a lucky draw.
          Once or if all the requirements of paragraphs 4.2.1 to 4.2.6 are met, entry into the
          Competition will be automatic.
        </div>
        <div class="mt-2">
          4.3 Employees, directors and agents of Vodacom and the Partner (where applicable) all
          participating stores, their immediate families, life partners, business partners and
          associates and any person directly involved with the sponsoring, devising, production,
          management or marketing of this Competition are not eligible to enter this
          Competition.
        </div>
        <div class="mt-2">
          4.4 In the event that the registered user of the SIM Card or Vodacom account (i.e., the
          participant in the Competition) is not the person that is responsible for payment of the
          Vodacom account/ charges on the Vodacom account, then the Prize will be awarded
          to the person that is responsible for payment of the Vodacom account.
        </div>
        <div class="mt-2">
          4.5 Standard data costs apply as per the user’s Service Provider and existing price plan
          for data usage. Vodacom shall not be liable for any costs incurred by the winners for
          claiming any Prizes, where applicable.
        </div>
        <div class="mt-2">
          5 Prize draw
        </div>
        <div class="mt-2">
          5.1 Winners will be randomly selected daily in real time from all eligible entries who meet
          all the requirements set out in clause 4 above.
        </div>
        <div class="mt-2">
          6 Winner Validation
        </div>
        <div class="mt-2">
          6.1 All the information provided or related to this Competition shall be managed, captured
          and approved by Vodacom and/or the Partner (where applicable).
        </div>
        <div class="mt-2">
          6.2 Vodacom and/or the Partner (where applicable) will use reasonable efforts to contact
          the winners telephonically or via SMS on the MSISDN used to enter the competition.
        </div>
        6.3 The claim for any Prize will be subject to security and validation, and Vodacom
        reserves the right to withhold any Prize until it is satisfied that the claim by a particular
        winner is valid. The selected winners must be able to identify themselves, in a manner
        determined by Vodacom, as the qualifying participants to the Competition and are
        required to comply with the validation procedure, determined by Vodacom, to claim
        any Prizes, failing which such winner will forfeit the Prize and the Prize will be awarded
        to the next selected qualifying participant.
        <div class="mt-2">
          6.4 In the event that Vodacom requires certain documentation to be submitted by a
          particular winner and same has not been received at the agreed cut-off date
          communicated to the winner, or no effort is made on the part of any of the winners to make special arrangements to meet the deadline set by Vodacom, such Prize will be
          forfeited. Vodacom then reserves the right to award the Prize to the finalist that is next
          in line.
        </div>
        <div class="mt-2">
          6.5 Vodacom and/or the Partner (where applicable) shall request the winners’ consent in
          writing to their image and/or likeness being used and published by Vodacom in
          connection with the Competition for a period of 12 (twelve) months after they have
          been announced as winners. The winners may decline the use of their image and/or
          likeness by Vodacom.
        </div>
        <div class="mt-2">
          7 Prize Delivery
        </div>
        <div class="mt-2">
          7.1 If the participant is a winner, a notification message will be displayed on the screen
          immediately after answering the 5 (five) questions correctly. Additionally, the winner
          will receive an SMS within 72 (seventy-two) hours confirming that the airtime or data
          bundles have been allocated to the winner’s MSISDN.
        </div>
        <div class="mt-2">
          7.2 If a winner cannot accept a Prize for any reason whatsoever, the Prize will be awarded
          to the next selected participant.
        </div>
        <div class="mt-2">
          7.3 All risks and ownership of the Prizes shall pass to the winners on transfer/delivery
          thereof and hence all of Vodacom’s obligations regarding the Competition as well as in
          regard to the Prizes shall terminate.
        </div>
        <div class="mt-2">
          8 Personal information
        </div>
        <div class="mt-2">
          8.1 User data, including personal information collected via the Competition will not be
          used for any other purpose than for execution of the Competition and will be
          processed in line with the Vodacom’s Privacy Statement which is incorporated into
          these terms and conditions. The Privacy Statement sets out how amongst other things
          Vodacom collects, use and shares subscribers personal information and how it
          protects subscriber’s privacy when processing their information.
        </div>
        <div class="mt-2">
          8.2 By continuing, a subscriber confirms that the Vodacom Privacy Statement has been
          read and understood. The latest Privacy Statement can be found on the Vodacom
          website at https://www.vodacom.co.za/vodacom/privacy-
          policy/terms?icmp=Home/Footer/PrivacyPolicy.
        </div>
        <div class="mt-2">
          9 General
        </div>
        <div class="mt-2">
          9.1 Participants may obtain a copy of the competition rules on the URL:
          https://termsandconditions.quizinc.co.za/daily-five.
        </div>
        <div class="mt-2">
          9.2 Nothing in these Terms and Conditions is intended to, or must be understood to,
          unlawfully restrict, limit or avoid any rights or obligations, as the case may be, created
          for either the participant or Vodacom in terms of the Consumer Protection Act, 68 of
          2008 (&quot;CPA&quot;).
        </div>
        <div class="mt-2">
          9.3 The Prize is not transferable and is not exchangeable for another Prize or cash and
          cannot be sold.
        </div>
        <div class="mt-2">
          9.4 The decision of Vodacom in respect of disputes arising out of this Competition shall be
          dealt with by Vodacom in terms of these Terms and Conditions. The decision of
          Vodacom in this regard shall be final and binding on the Parties and no
          correspondence will be entered into.
        </div>
        <div class="mt-2">
          9.5 Winners are only entitled to win 1 (one) Prize each per day.
        </div>
        <div class="mt-2">
          9.6 In the event of a dispute with regard to any aspect of the Competition and/or the
          Terms and Conditions, Vodacom’s decision will be final, and binding and no
          correspondence will be entered into.
        </div>
        <div class="mt-2">
          9.7 Vodacom and/or the Partner may refuse to award a Prize if entry procedures or these
          Terms and Conditions have not been adhered to or if it detects any irregularities or
          fraudulent practices. Vodacom is entitled in its entire discretion to reject any participant
          for any reason and will not be obliged to notify participants that they have not been
          successful.
        </div>
        <div class="mt-2">
          9.8 Vodacom and/or the Partner and/or any other person or party associated with the
          Competition, their associated companies, agents, contractors and sponsors and any of
          its personnel involved in the Competition, shall not be liable whatsoever for any loss or
          damage incurred or suffered (including but not limited to direct or indirect or
          consequential loss), death or personal injury suffered or sustained arising from either
          the participant entering the Competition or from it claiming a Prize.
        </div>
        <div class="mt-2">
          9.9 Vodacom is not liable for any technical failures affecting participation and / or Prize
          redemption process of the Competition. In addition, neither Vodacom nor its agents
          shall be responsible for any loss or misdirected entries, including entries that were not
          received due to any failure of hardware, software, or other computer or technical
          systems affecting participating in and/or the Prize redemption process of the
          Competition.
        </div>
        <div class="mt-2">
          9.10 Vodacom, its directors, employees, agents, and distributors, are not responsible for
          any misrepresentation (whether written or verbal) in respect of any Prize nor in respect
          of any warranties, guarantees or undertakings given by any person other than
          Vodacom itself.
        </div>
        <div class="mt-2">
          9.11 If Vodacom elects in its sole and absolute discretion or is required by law to alter, vary
          or cancel any aspect of the Competition, it will have the right to terminate the
          Competition being offered, with immediate effect and upon written notice being
          published on the Vodacom website. In such event all participants hereby waive any
          rights which they may have against Vodacom and its associated companies, agents,
          contractors and/or sponsors.
        </div>
        <div class="mt-2">
          9.12 All information relating to the Competition which is published on any marketing
          material will form part of these Terms and Conditions. In the event of any conflict
          between such marketing material and these Terms and Conditions, these Terms and
          Conditions shall prevail.
        </div>
        <div class="mt-2">
          9.13 The Competition is also subject to, and must be read in conjunction with, Vodacom’s
          existing terms and conditions applicable to its website and mobile site respectively.,
          which terms and conditions are available on the Vodacom website.
        </div>
      </div>
      <img class="mt-3 col-12" src="../../assets/divider-line.png">
    </div>
  </div>
</template>

<script>
import {environmentConfig} from "@/config/enviromentConfig";
import {mapState} from "vuex";
import DcbService from "@/services/dcbService";

export default {
  name: "DailyFive",
  computed: {
    ...mapState(['encryptedMsisdn'])
  },
  data() {
    return {
      dropDownVisible: false,
      openDropdown: null,
      selectedDropDown: null,
    }
  },
  methods: {
    toggleDropdown(dropdown) {
      this.openDropdown = this.selectedDropDown === dropdown ? null : dropdown;
      this.selectedDropDown = this.openDropdown;
    },
    isDropdownOpen(dropdown) {
      return this.openDropdown === dropdown;
    },
    async playNowClicked() {
      let redirectUrl = environmentConfig.dailyFiveRedirectUrl;
      if (this.encryptedMsisdn) {
        const decryptMsisdn = (await DcbService.decryptMsisdn(this.encryptedMsisdn)).data.rawMsisdn;
        redirectUrl += `/?msisdn=${decryptMsisdn}`;
      }
      window.open(redirectUrl, '_self');
    },
  },
}
</script>

<style scoped>
.vue-container {
  position: relative;
}

.header-text {
  font-size: 16px;
  font-weight: 800;
  z-index: 1;
}

.back-arrow {
  position: absolute;
  width: 80px;
  left: 5px;
  top: 10px;
}

.info-text {
  font-size: 14px;
  font-style: italic;
}

.header-text, .terms-conditions-dropdown-header {
  font-weight: 800;
}

.terms-conditions-dropdown-header {
  color: red;
}

.terms-conditions-text {
  font-size: 12px;
}

.accordion-content {
  color: #231f20;
  font-size: 11px;
}

.accordion {
  margin: 0 0 20px 0;
  padding: 0;
}

.paragraph.uppercase {
  text-transform: uppercase;
  font-weight: bold;
}


table, th, td {
  border: 1px solid;
}

</style>
