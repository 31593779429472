<template>
  <div class="d-flex flex-column align-items-center mt-5 col-12">
    <div class=" d-flex flex-column text-center align-items-center header-text col-7">
      TERMS & CONDITIONS
    </div>
    <div class="d-flex flex-column justify-content-start secondary-text mt-4 col-10">
      WELCOME TO TRIVIA TERMS AND CONDITIONS HUB.
    </div>
    <div class="d-flex flex-column justify-content-start tertiary-text mt-4 col-10">
      PLEASE SELECT THE PRODUCT YOU WOULD LIKE TO VIEW.
    </div>
    <div class="d-flex flex-column mt-4 col-10">
      <div @click="routeToPage('quiz-inc')" class="d-flex flex-row align-items-center justify-content-center game-container col-12">
        <div class="d-flex flex-column align-items-center col-4 justify-content-center">
          <img class="col-7" src="../assets/gameLogos/quiz-inc-logo.png">
        </div>
        <div class="d-flex flex-column align-items-center col-6 justify-content-center game-text">Quizinc</div>
        <div class="d-flex flex-column align-items-center col-3 justify-content-center">
          <img class="forward-arrow" src="../assets/forward-arrow.png">
        </div>
      </div>
      <div @click="routeToPage('stop-the-clock')" class="d-flex flex-row align-items-center justify-content-center game-container mt-3 col-12">
        <div class="d-flex flex-column align-items-center col-3 justify-content-center">
          <img class="col-7" src="../assets/gameLogos/stop-the-clock-logo.png">
        </div>
        <div class="d-flex flex-column align-items-center col-6 justify-content-center game-text">Stop the Clock</div>
        <div class="d-flex flex-column align-items-center col-3 justify-content-center">
          <img class="forward-arrow" src="../assets/forward-arrow.png">
        </div>
      </div>
      <div @click="routeToPage('unlock-three')" class="d-flex flex-row align-items-center justify-content-center game-container mt-3 col-12">
        <div class="d-flex flex-column align-items-center col-3 justify-content-center">
          <img class="col-7" src="../assets/gameLogos/unlock-3-logo.png">
        </div>
        <div class="d-flex flex-column align-items-center col-6 justify-content-center game-text">Unlock 3</div>
        <div class="d-flex flex-column align-items-center col-3 justify-content-center">
          <img class="forward-arrow" src="../assets/forward-arrow.png">
        </div>
      </div>
      <div @click="routeToPage('head-to-head')" class="d-flex flex-row align-items-center justify-content-center game-container mt-3 col-12">
        <div class="d-flex flex-column align-items-center col-4 justify-content-center">
          <img class="col-7" src="../assets/gameLogos/head-2-head-logo.png">
        </div>
        <div class="d-flex flex-column align-items-center col-6 justify-content-center game-text">Quizinc Head 2 Head</div>
        <div class="d-flex flex-column align-items-center col-3 justify-content-center">
          <img class="forward-arrow" src="../assets/forward-arrow.png">
        </div>
      </div>
      <div @click="routeToPage('daily-five')" class="d-flex flex-row align-items-center justify-content-center game-container mt-3 col-12">
        <div class="d-flex flex-column align-items-center col-4 justify-content-center">
          <img class="col-5" src="../assets/gameLogos/daily-five-logo.png">
        </div>
        <div class="d-flex flex-column align-items-center col-6 justify-content-center game-text">Daily Five</div>
        <div class="d-flex flex-column align-items-center col-3 justify-content-center">
          <img class="forward-arrow" src="../assets/forward-arrow.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingPage",
  methods: {
    routeToPage(pageRoute) {
      this.$router.push(`/${pageRoute}`)
    }
  },
}
</script>

<style scoped>
.header-text {
  font-size: 26px;
  font-weight: 800;
  line-height: 1.05;
}

.secondary-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.05;
}

.tertiary-text {
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  line-height: 1.05;
}

.game-container {
  position: relative;
  height: 65px;
  border: 2.5px solid red;
  border-radius: 14px;
  background: #FFFFFF;
}

.game-text {
  font-size: 12px;
  font-weight: 800;
}

.forward-arrow {
  position: absolute;
  width: 16px;
  right: 15px;
}
</style>
