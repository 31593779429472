<template>
  <div class="d-flex flex-column align-items-center">
    <div class="app-container col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3 vue-container">
      <router-view/>
    </div>
  </div>
</template>

<script>

export default {
  beforeMount() {
    this.setTabName()
  },
  components: {},
  computed: {},
  methods: {
    setTabName() {
      document.title = 'Quizinc T&Cs Hub';
    },
  },
  watch: {},
};
</script>

<style>

.vue-container {
  position: relative;
  font-family: 'Poppins', sans-serif;
  background-image: url("assets/background/bg-img.jpg");
  min-height: 100vh;
}

</style>
